import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThankYou from './pages/ThankYou';

const About = React.lazy(() => import('./pages/About'));
const BehavioralHealth = React.lazy(() => import('./pages/BehavioralHealth'))
const Cardiology = React.lazy(() => import('./pages/Cardiology'))
const Contact = React.lazy(() => import('./pages/Contact'));
const Home = React.lazy(() => import('./pages/Home'));
const InternalMedicine = React.lazy(() => import('./pages/InternalMedicine'))
const PediatricCare = React.lazy(() => import('./pages/PediatricCare'))
const PrimaryCare = React.lazy(() => import('./pages/PrimaryCare'))
const Providers = React.lazy(() => import('./pages/Providers'))
const Services = React.lazy(() => import('./pages/Services'))
const SkinCancer = React.lazy(() => import('./pages/SkinCancer'))
const UrgentCare = React.lazy(() => import('./pages/UrgentCare'))
const Vaccinations = React.lazy(() => import('./pages/Vaccinations'))

const BookAppointment = React.lazy(() => import('./pages/BookAppointment'))

const BlogsContent = React.lazy(() => import('./pages/BlogsContent')) 
const Blogs = React.lazy(() => import('./pages/Blogs'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'))
const Error = React.lazy(() => import('./pages/Error'))

function App() {
    return (
        <>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/providers" element={<Providers />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact-us" element={<Contact />} />
                    {/* <Route path="/behavioral-health" element={<BehavioralHealth />} />
                    <Route path="/cardiology" element={<Cardiology />} />
                    <Route path="/internal-medicine" element={<InternalMedicine />} />
                    <Route path="/pediatric-care" element={<PediatricCare />} /> */}
                    <Route path="/primary-care" element={<PrimaryCare />} />
                    {/* <Route path="/skin-cancer" element={<SkinCancer />} />
                    <Route path="/urgent-care" element={<UrgentCare />} />
                    <Route path="/vaccinations" element={<Vaccinations />} />
                    <Route path="/book-an-appointment" element={<BookAppointment />} /> */}
                    <Route path="/thank-you" element={<ThankYou />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />
                    <Route path="/blogs" element={<Blogs />} />
                    <Route path="/blogs/:slug" element={<BlogsContent />} />
                    
                    <Route path="*" element={<Error />} />
                </Routes>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                />
            </Suspense>
        </>
    )
}

export default App;