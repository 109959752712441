import React from 'react'
import {Link} from 'react-router-dom'
import { Facebook, GeoAltFill, Instagram, PlayFill, TelephoneFill, Twitter } from 'react-bootstrap-icons';

const Footer = () => {
  return (
        <>
            <footer id="website-responsive">
                <div className="footer-bg" >
                    <div className='container-zk py-4'>
                        <div className='row pt-4 text-white gx-md-5 justify-content-evenly'>
                            <div className='col-lg-3 col-md-4 col-11'>
                                <span className='text-work-sans text-dark fs-zk-26 bg-zk-primary px-3 py-1' style={{fontWeight:"600"}}>Services</span>
                                <ul className='list-unstyled mt-3'>
                                    <li><Link to='/urgent-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Urgent Care</Link></li>
                                    <li><Link to='/primary-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Primary Care</Link></li>
                                    <li><Link to='/cardiology' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Cardiology</Link></li>
                                    <li><Link to='/pediatric-care' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Pediatric Care</Link></li>
                                </ul>
                            </div>
                            <div className='col-lg-3 col-md-4 col-11'>
                                <span className='text-work-sans text-dark fs-zk-26 text-nowrap bg-zk-primary px-3 py-1' style={{fontWeight:"600"}}>Quick Links</span>
                                <ul className='list-unstyled mt-3'>
                                    <li><Link to='/about-us' className='text-decoration-none text-light lh-lg fs-zk-17'><PlayFill className='me-1'/>About us</Link></li>
                                    <li><Link to='/contact-us' className='text-decoration-none text-light lh-lg fs-zk-17'><PlayFill className='me-1'/>Contact Us</Link></li>
                                    <li><Link to='/privacy-policy' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Privacy Policy</Link></li>
                                    <li><Link to='/terms-conditions' className='text-decoration-none text-light lh-lg fs-zk-17'> <PlayFill className='me-1'/>Terms & Conditions</Link></li>
                                </ul>
                            </div>

                            <div className='col-md-4 col-11 my-auto'>
                            {/* <span className="fs-zk-30 text-white text-raleway text-uppercase" style={{fontWeight:"600", letterSpacing:"1px"}}>Premier Medical Clinic</span>
                                <p className='py-2'>
                                Medical clinic in Scottsboro, Alabama. We accept most insurance providers. Call Us for appointments!
                                </p> */}
                                
                                {/* <ul className='list-unstyled'>
                                    <li><a href='tel:+12562591234' className='text-decoration-none text-light lh-lg '><TelephoneFill className='me-2' /> 256-259-1234</a></li>
                                    <li><a href="https://goo.gl/maps/HbJDSDyCgqG3ucfa8" rel="noreferrer" target="_blank" className="text-decoration-none text-np-success"><span className=' text-light lh-lg'><GeoAltFill className='me-2' />506 Harley St Suite D, Scottsboro, AL 35768, US</span></a></li>
                                    
                                </ul>   
                                  */}
                                <ul className='list-unstyled mt-3 fw-600 fs-zk-22'>
                                <li><a href='tel:+0000000000' className='text-decoration-none text-light '> 000-000-0000 </a></li>

                                <div className="py-1" />

                                <li><a href="mailto:" rel="noreferrer" target="_blank" className="text-decoration-none text-np-success"><span className=' text-light'> www.samplewebiste.com </span></a></li>

                                <div className="py-1" />
                                <div className="py-1" />

                                <li className="">
                                <span className="bg-zk-primary px-3 py-1 pb-2">
                                   <a href="https://www.facebook.com/" className='' target='_blank'> 
                                   <Facebook className='me-4 text-dark'/>
                                   </a> 
                                   <a href="https://www.instagram.com/" className='' target='_blank'> 
                                   <Instagram className='me-4 text-dark'/>
                                   </a> 
                                   <a href="https://twitter.com/" className='' target='_blank'> 
                                   <Twitter className='me-4 text-dark'/>
                                   </a>
                                </span>
                                </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='py-3 bg-white'>
                    <div className='container-zk'>
                        <div className='text-center'>
                            <p className='p-0 m-0'>Copyright © 2024 Sample Website </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
  )
}

export default Footer
